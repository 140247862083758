<template>
  <div class="navbar-container">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <transition-group name="breadcrumb" mode="out-in">
        <el-breadcrumb-item v-for="route in routes" :key="route.path" :to="{ path: route.path }">
          {{ route.meta.title }}
        </el-breadcrumb-item>
      </transition-group>
    </el-breadcrumb>

    <!-- 右侧管理工具 -->
    <div class="setting">
      <div style="display: inline-block; padding-right: 20px; font-size: 14px">欢迎用户：{{ username }}</div>
      <el-dropdown trigger="click">
        <i class="el-icon-s-tools"></i>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/">
            <el-dropdown-item> 首页 </el-dropdown-item>
          </router-link>
          <el-dropdown-item v-if="aidAndBid == '0'" @click.native="openModal">切换图书馆</el-dropdown-item>
          <el-dropdown-item divided @click.native="logout"> 退出登录 </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- 选择图书馆 -->
      <modal :is-visible="isVisible" @close="closeModal">
        <template v-slot:header>
          <h3 style="line-height: 20px;font-size: 16px;color: #17233d;font-weight: 500;">请选择您要切换的角色</h3>
        </template>
        <div v-if="libraryObj !== undefined">
          <div class="square" @click="loginLibrary()">
            <div style="font-size:14px;color: #858585;line-height:2rem">馆组：</div>
            <p>{{ libraryObj.groupName }}</p>
            <hr>
            <p style="font-size:14px;color: #858585;">权限角色：</p>
            <p>馆组管理员</p>
          </div>
          <hr>
        </div>
        <div class="listLibrary">
          <div class="square" v-for="item in libraryList" :key="item.index" @click="loginLibrary(item)">
            <div style="font-size:14px;color: #858585;">馆组：</div>
            <p> {{ item.branchName }}</p>
            <!-- <p>{{ item.branchPosition }}</p> -->
            <hr>
            <p style="font-size:14px;color: #858585;">权限角色：</p>
            <p style="">分馆管理员</p>
          </div>
        </div>
        <template v-slot:footer>
          <el-button plain @click="closeModal">取消</el-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { getInfo, logout } from '@/api/login'
import { selectLibrary, listLibrary, AdmindetailsData } from '@/api/library'
import { setToken, setAid, setBid, setRole, getRole } from '@/utils/auth'

export default {
  data() {
    return {
      aidAndBid:"",
      
      username: undefined,
      isVisible: false,
      libraryList: [],//图书列表,
      libraryObj: {},//总馆信息

      showDialog: true
    }
  },
  computed: {
    routes() {
      return this.$route.matched.filter(item => {
        return item.meta.title
      })
    }
  },
  created() {
    getInfo().then(({ data }) => {
      this.username = data.username
      this.aidAndBid = data.bid
      
      // console.log(data.aid+"=="+data.bid)
      // console.log(this.aidAndBid)
      setAid(data.aid)
      setBid(data.bid)
      if (!this.username) {
        // 未登录
        this.$router.push('/')
      }
    })
  },
  methods: {
    logout() {
      logout()
      this.$router.push('/login')
    },
    // 获取总馆信息
    mainLibrary() {
      AdmindetailsData().then(res => {
        if (res.code === '200') {
          this.libraryObj = res.data
          this.isVisible = true;
        }
      })
    },
    // 获取分馆信息
    libraryLists() {
      listLibrary().then(({ data }) => {
        this.libraryList = data.libraryBranchData
      })
    },
    // 进入分馆
    loginLibrary(item) {
      console.log(item);
      let parameter = {}
      if (item === undefined) {
        parameter = { aid: this.libraryObj.id, bid: 0 }
        // parameter = { aid: this.libraryObj.id, bid: 0 }
      } else {
        parameter = { aid: item.aid, bid: item.id }
        // parameter = { aid: item.aid, bid: item.bid }
      }
      selectLibrary(parameter).then(({ data }) => {
        // 切换图书馆之后存入最新的角色、token和bid
        setRole(data.role)


        setToken(data.token)
        setBid(data.bid)
        this.isVisible = false;//关闭弹框
        // 刷新当前页面
        window.location.replace(window.location.pathname);
        // 在页面刷新后跳转到目标页面
        this.$router.push('/dashboard').catch(err => {
          console.log(err);
        });
      })
    },
    // 取消按钮
    closeModal() {
      this.isVisible = false;
    },//取消切换弹框
    //打开切换界面
    openModal() {
      this.mainLibrary()
      this.libraryLists()
    },
  }
}
</script>

<style scoped>
.setting .square {
  border-radius: 15px;
  cursor: pointer;
  line-height: 1.5rem;
  text-align: center;
  padding: 10px;
  width: 15%;
  height: 20%;
}

.navbar-container {
  display: block;
  text-align: left;
  line-height: 1;
}

.el-breadcrumb,
.setting {
  display: inline-block;
}

.setting {
  float: right;
}

.setting i {
  font-size: 21px;
}

.setting i:hover {
  color: #409eff;
  cursor: pointer;
}

a {
  text-decoration: unset;
}
</style>