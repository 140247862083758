import request from '@/utils/request'

// 获取分馆信息
export function listLibrary(params) {
  return request({
    url: '/libraryBranch/findAll',
    method: 'get',
    params
  })
}

// 图书馆馆组数据
export function libraryData(params) {
  return request({
    url: '/libraryGroup/count',
    method: 'get',
    params
  })
}

// 选择分馆
export function selectLibrary(data) {
  return request({
    url: '/switchRole',
    method: 'post',
    data
  })
}
// 查询总馆详细
export function AdmindetailsData() {
  return request({
    url: '/libraryGroup/findOne',
    method: 'get',
  })
}

// 查询分馆详情
export function libraryDetailsData() {
  return request({
    url: '/libraryBranch/findOne',
    method: 'get',
  })
}

//编辑分馆馆组信息 
export function libraryEdit(data){
  return request({
    url:"/libraryGroup/edit",
    method:'post',
    data
  })
}


// 添加标签



